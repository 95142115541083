@import url('https://fonts.googleapis.com/css2?family=Dangrek&family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dangrek&display=swap');

.main-background {
    background-image: url(images/background.jpg);
    height: 100vh;
    background-size: cover;
    overflow-y: auto;


    /* background-image: url(images/background.jpg);
    background: rgb(255 255 255 / 0%) url(https://evil-mart.com/img/head-cloud-bg.svg) no-repeat center top;
    background-clip: content-box;
    background-size: cover; */
}

.col-span-1.lg\:col-span-1.table-home {

    margin-top: 39px;
    border-radius: 15px;
    background-color: #ffffffed;
    overflow: hidden;
}

.wrap-text {
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
}


.table-main,
.table-fixed {
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    font-family: 'Dangrek', cursive;
    font-family: 'Quicksand', sans-serif;
    background: url(images/background-table.svg) center bottom no-repeat content-box rgba(241, 241, 241, 0.322);
}

table {
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    font-family: 'Dangrek', cursive;
    font-family: 'Quicksand', sans-serif;

}

thead.css-0 {
    /* background-color: blanchedalmond; */
    color: white !important;
}

th.css-1zts0j {
    color: rgb(0, 0, 0);
    font-weight: bold;
}

td.items-center.css-xumdn4 {
    display: flex;
    justify-content: space-evenly;
    /* padding: 25px; */
}

dd.chakra-stat__help-text.css-gbp32c {
    /* opacity: 0.8; */
    margin-bottom: var(--chakra-space-2);
    font-size: var(--chakra-fontSizes-sm);
    background: #ffffff;
    color: #83b803;
    width: fit-content;
    border-radius: 7px;
    padding: 4px;
    text-align: center;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #83b803;
}

td.items-center.css-xumdn4 {
    padding: 25px 10px;
}

.row-banner {
    background: url(images/bg-pattern.svg) center bottom no-repeat content-box, linear-gradient(90deg, rgb(112 157 3) 0.01%, rgb(230 241 0) 100.01%);
    text-align: left;
    border-radius: 10px;
}

.banner {
    text-align: left;
    padding: 1rem 3rem 1rem;
    border-radius: 10px;
    /* background: linear-gradient(90deg, rgb(112 157 3) 0.01%, rgb(230 241 0) 100.01%); */
}

.text-banner {
    color: #ffffff;
}


.button-colum {
    color: rgb(255, 255, 255);
    background-color: rgb(30, 33, 42);
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-family: 'Dangrek', cursive;
    font-weight: 100;
    font-size: 16px;
    padding: 12px;
    height: 29px;
}

.button-contact {
    margin: 0rem 0.5rem;
    color: rgb(255, 255, 255);
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border-color: white;
    box-shadow: rgb(255 255 255) 0px 0px 0px 0.1rem inset !important;
    font-weight: 100;
    padding: 12px;
    font-size: 16px;
    font-family: 'Dangrek', cursive;
    height: 29px;

}

.banner-text {
    padding: 12px 0px;
}

.text-sm {
    font-size: 18px;
    font-weight: 900;
    font-family: 'Quicksand', sans-serif;
}

.text-price-show {
    color: #ffffff;
    margin-left: 10px;
}

.telegram-link {
    display: inline-block;
    text-decoration: none;
    border-radius: 10px;
}

.telegram-icon {
    display: inline-block;
    width: 20px;
    /* Adjust the width and height as needed */
    height: 20px;
    background-image: url(images/youtube_icon.png);
    /* Replace with the path to your Telegram icon image */
    background-size: cover;
    margin-right: 7px;
    /* Adjust the spacing between the icon and text */
}

.rs-modal-content {
    border-radius: 20px;
}

.rs-modal-content h5 {
    color: #729707;
}

.rs-modal-content .popup-buy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    padding: 11px 0;
}

.rs-modal-content p.font-bold {
    margin-top: 5px;
}

.red-text {
    color: red;
}

/* button.button-buy.rs-btn.rs-btn-primary {
    background: url(images/Pattern.svg) center bottom no-repeat content-box, linear-gradient(90deg, rgb(112 157 3) 0.01%, rgb(230 241 0) 100.01%);
} */

button.button-buy.rs-btn.rs-btn-default.rs-btn-disabled {
    background: url(images/Pattern.svg) center bottom no-repeat, linear-gradient(90deg, rgba(111, 157, 3, 0.144) 0.01%, rgba(229, 241, 0, 0.233) 100.01%);
}

button.button-buy.rs-btn.rs-btn-default {
    background: url(images/Pattern.svg) center bottom no-repeat, linear-gradient(90deg, rgb(112 157 3) 0.01%, rgb(230 241 0) 100.01%);
    font-weight: 600;
}

.button-more {
    margin-top: 10px;
    width: 100%;
    background-color: #d9d9d99c;
    color: black;
    height: 28px;
    font-weight: 700;
}

.scroll-main::-webkit-scrollbar {
    width: 6px;
}

.scroll-main::-webkit-scrollbar-thumb {
    background: #f0f0f0d0;
    border-radius: 20px;
}

.scroll-main::-webkit-scrollbar-track {
    background: #dddddd0e;
    border-radius: 20px;
}

nav.chakra-breadcrumb.main.css-0 {
    color: white;
    font-weight: 700;
}

.text-alert {
    font-size: 16px;
    color: #262525;
    background-color: #ffbc0e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin-top: 20px;
    height: 38px;
}

/* Define the animation for the scrolling effect */
@keyframes marquee {
    1% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.marquee-container {
    animation: marquee 20s linear infinite;
    white-space: nowrap;
    transform: translateX(100%);
    text-align: left;
    padding-top: 5px;
}

.marquee {
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.5px;
    color: #ffffff;
    font-family: 'Dangrek', cursive;
    display: block;
}

.banner-alert {
    /* font-size: 15px; */
    background-color: #ff4431;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 30px;
}