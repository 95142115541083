@import url('https://fonts.googleapis.com/css2?family=Dangrek&family=Quicksand:wght@500&display=swap');


.user-dashboard {
    position: relative;
    height: 100%;
    width: 100%; 
    background-color:#ffffff61; 
    margin-top: 35px;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 10px 40px;
    height: 90px;
    font-weight: 800;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    color: black;
}
.body-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}


.par-title {
    width: 100px;
    margin-right: 10px;
}

p {
    text-align: start;
    padding: 3px 10px;
    
}
.price-show {
    font-size: 25px;
    color: #000000;
    font-weight: 700;
}


.item-info {
    display: flex;
    align-items: center;
    height: 22px;
    margin: 25px 0;
}

.intem-balance {
    display: flex;
    align-items: center;
    height: 30px;
    margin: 25px 0;
    /* height: 100px; */
    border-radius: 10px;
    justify-content: space-evenly;
}

.profile-info {
    border-radius: 10px;
    display: grid;
    margin: 0 auto;
    align-items: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    width: 390px;
    border: 1px solid #ffffff;
    
}

.par-content {
    width: 400px;
    background-color: #8d8d8d18;
    /* border: 2px solid #67e74e; */
    border-radius: 5px;
}

.button-change-api {
    height: 28px;
    margin-left: 20px;
}

.deposit-title {
    width: 350px;
    margin-right: 10px;
    font-size: 24px;
}

.deposit-content {
    width: 480px;
    margin-right: 10px;
}

.aba_logo1 {
    height: 67.52px;
    background: #e21a1a;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    width: 350px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

.aba_logo2 {
    height: 80px;
    background: white;
    width: 350px;
    display: flex;
    margin: 0 auto;
    align-items: baseline;
    flex-direction: column;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
}

.text-10 {
    font-weight: 700;
    font-size: 15px;
    padding-left: 25px;
    padding-top: 25px;
    color: #000;
    font-family: 'Quicksand', sans-serif;
}
.text-price {
    font-weight: 800;
    font-size: 17px;
    padding-left: 25px;
    color: #000;
    font-family: 'Quicksand', sans-serif;
}

.amount-currency {
    position: relative;
    font-size: 17px;
    font-weight: 900;
    margin-bottom: 0;
    padding-left: 10px;
    width: 100% !important;
    color: #17bb25;
    font-family: 'Quicksand', sans-serif;
}

.aba_logo3 {
    height: 300px;
    background: white;
    width: 350px;
    display: flex;
    margin: 0 auto;
    padding-top: 10px;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}

.line-divider {
    background-image: url(https://link.payway.com.kh/images/line-divider.svg);
    width: 100%;
    height: 1px;
    margin-bottom: 10px;
}

.text-aba {
    color: #17bb25;
    font-weight: 800;
}

.deposit-content-kh {
    font-family: 'Dangrek', cursive;
}

.background-svg {
    background: url(https://evil-mart.com/img/head-cloud-bg.svg) center top / cover no-repeat content-box rgb(255 255 255 / 42%);
    color: #ffffff;
    background: #ffffffe8;
    box-shadow: 0 2px 50px rgba(33,33,33,.1);
}

.dashboard-background {
    background: url(https://evil-mart.com/img/head-cloud-bg.svg) center top / cover no-repeat content-box rgb(177 183 13);
    height: 333px;
}



textarea.chakra-textarea.user-show.css-1bki5fo {
    min-height: 200px;
    resize: none;
    outline: none;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #cdd901;
    color: black;
    line-height: 20px;
    outline: none;
    font-size: 14px;
}

textarea.chakra-textarea.user-input-cookie.css-1bki5fo {
    min-height: 40px;
    resize: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid #cdd901;
    color: black;
}

textarea.chakra-textarea.user-input.css-1bki5fo {
    min-height: 220px;
    resize: none;
    outline: none;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #cdd901;
    color: black;
}

.textarea {
    border-radius: 12px;
}

.button-start {
    position: absolute;
    margin-top: 10px;
    text-align: center;
    /* width: 100px; */
    outline: none;
    cursor: pointer;
    background-color: #b8c002;
    height: 30px;
    padding: 0 15px;
    border-radius: 5px;
    border: 1px solid #f1ff00;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    right: 10px;
    bottom: 10px;
}



.button-copy {
    position: absolute;
    margin-top: 10px;
    text-align: center;
    width: 100px;
    outline: none;
    cursor: pointer;
    background-color: #a5a5a580;
    height: 30px;
    padding: 0 15px;
    border-radius: 5px;
    border: 1px solid #ffffffc7;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    right: 37px;
    bottom: 10px;
}

.textarea-scrollbar {
    margin: auto;
    height: 250px;
    width: 500px;
}

.w-40 {
    width: 10rem;
    padding-bottom: 20px;
}

.css-1flyc9m {
    color: black;
}

.scroll4::-webkit-scrollbar {
    width: 10px;
  }
   
  .scroll4::-webkit-scrollbar-thumb {
    background: #9bb317d0;
    border-radius: 20px;
  }
  
  .scroll4::-webkit-scrollbar-track {
    background: #dddddd31;
    border-radius: 20px;
  }

  .scroll {
    width: 20px;
    height: 200px;
    overflow: auto;
    float: left;

  }

a.chakra-breadcrumb__link.active.css-1u2cnya {
    color: #fbfbfb;
    font-size: 18px;
    background-color: #a0c500e0;
    padding: 3px 9px;
    border-radius: 17px;
    transition: all .3s;
}