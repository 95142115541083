@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


.App-logo {
  width: 100vw;
  height: 100vh;
  text-align: center;
  /* background: rgb(2,0,36); */
  /* background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgb(108 199 56) 100%); */
  /* background-image: url(images/background.jpg); */
  min-height: calc(90vh - -120px);
  background-size: cover;
  font-family: 'Poppins', sans-serif;
  backdrop-filter: blur(10px);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.default-font {
  font-family: 'Poppins', sans-serif;
}

.App-link {
  color: #61dafb;
}

.body-user-topup {
  padding: 35px 50px;
}

.body-user {
  padding: 35px 32px;
}

.header-bg{
  background: url(https://evil-mart.com/img/head-cloud-bg.svg) center top / cover no-repeat content-box rgb(177 183 13);
  background-size: cover;
}

.button-buy {
  width: 100%;
  background-size: cover;
  border-radius: 10px;
  color: white !important;
}

.popup-buy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding: 15px 0;
}

input.user-input-rg.rs-input {
  color: #000c;
  font-size: 15px;
  flex: 1;
  background: transparent;
  margin-left: 5px;
  border: none;
  outline: none;
  text-align: right;
  margin: 0!important;
}

a.chakra-breadcrumb__link.css-1u2cnya {
  text-decoration: none;
  --rs-text-link-hover: #9fd300;

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagination-style {

}

