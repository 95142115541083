@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 5px;
}
 
body::-webkit-scrollbar-track {
  background-color: darkgrey;
}
 
body::-webkit-scrollbar-thumb {
  border-radius: 15px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.pagination ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.pagination li {
  margin: 0 3px;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  border-radius: 4px;
}

.pagination .active {
  background-color: #0074c2;
  color: #fff;
  font-weight: bold;
}

.pagination li:hover {
  background-color: #0074c2;
  color: #fff;
}
